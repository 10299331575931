// Generated by Framer (705c3f6)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, Link, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/MxpARFqyv";

const cycleOrder = ["cpCdle2FV", "lWn7Mrr7U"];

const serializationHash = "framer-ldyKD"

const variantClassNames = {cpCdle2FV: "framer-v-xsogwp", lWn7Mrr7U: "framer-v-1rq1vgu"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Text Link Mono - Hover": "lWn7Mrr7U", "Text Link Mono": "cpCdle2FV"}

const getProps = ({height, id, link, title, width, ...props}) => { return {...props, NpUBY8qIL: title ?? props.NpUBY8qIL ?? "Projects version 2", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "cpCdle2FV", YBBeUo0_3: link ?? props.YBBeUo0_3} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, NpUBY8qIL, YBBeUo0_3, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "cpCdle2FV", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEntereicqhm = activeVariantCallback(async (...args) => {
setVariant("lWn7Mrr7U")
})

const onMouseLeave1t8dp4b = activeVariantCallback(async (...args) => {
setVariant("cpCdle2FV")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={YBBeUo0_3}><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-xsogwp", className, classNames)} framer-1r70ltr`} data-framer-name={"Text Link Mono"} data-highlight layoutDependency={layoutDependency} layoutId={"cpCdle2FV"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onMouseEnter={onMouseEntereicqhm} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({lWn7Mrr7U: {"data-framer-name": "Text Link Mono - Hover", onMouseLeave: onMouseLeave1t8dp4b}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-12d68ky"} data-styles-preset={"MxpARFqyv"}>Projects version 2</motion.p></React.Fragment>} className={"framer-11ggife"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"nNdO36MOs"} style={{opacity: 0.5}} text={NpUBY8qIL} variants={{lWn7Mrr7U: {opacity: 1}}} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ldyKD.framer-1r70ltr, .framer-ldyKD .framer-1r70ltr { display: block; }", ".framer-ldyKD.framer-xsogwp { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: 570px; }", ".framer-ldyKD .framer-11ggife { -webkit-user-select: none; flex: 1 0 0px; height: auto; pointer-events: auto; position: relative; user-select: none; white-space: pre-wrap; width: 1px; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-ldyKD.framer-xsogwp { gap: 0px; } .framer-ldyKD.framer-xsogwp > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-ldyKD.framer-xsogwp > :first-child { margin-left: 0px; } .framer-ldyKD.framer-xsogwp > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 13
 * @framerIntrinsicWidth 570
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"lWn7Mrr7U":{"layout":["fixed","auto"]}}}
 * @framerVariables {"NpUBY8qIL":"title","YBBeUo0_3":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramermTygBv2wm: React.ComponentType<Props> = withCSS(Component, css, "framer-ldyKD") as typeof Component;
export default FramermTygBv2wm;

FramermTygBv2wm.displayName = "Text Link - Mono";

FramermTygBv2wm.defaultProps = {height: 13, width: 570};

addPropertyControls(FramermTygBv2wm, {variant: {options: ["cpCdle2FV", "lWn7Mrr7U"], optionTitles: ["Text Link Mono", "Text Link Mono - Hover"], title: "Variant", type: ControlType.Enum}, NpUBY8qIL: {defaultValue: "Projects version 2", displayTextArea: false, title: "Title", type: ControlType.String}, YBBeUo0_3: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramermTygBv2wm, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})